import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./scenes/dashboard";
import Remessas from "./scenes/transactions";
import Providers from "./scenes/providers";
import Reports from "./scenes/reports";
import { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Register from "./scenes/register/register";
import Login from "./scenes/register/login";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import { storeContext } from "./context/storeContext";
import Beneficiarios from "./scenes/Recipients";
import ClientsTab from "./scenes/Clients/clients_tab";
import SettingsTab from "./scenes/settings";
import AdminTab from "./scenes/admin";
import ResetPassword from "./scenes/register/resetPassword";
import ResetPasswordLink from "./scenes/register/resetPasswordLink";
import PageNotFound from "./404";
import Layout from "./layout";

function App() {
  const [theme, colorMode] = useMode();
  const [storesList, setStoresList] = useState([]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <storeContext.Provider value={[storesList, setStoresList]}>
            <Routes>
              <Route path="/register/:price_id" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="/reset-password-link/:id/:token"
                element={<ResetPasswordLink />}
              />

              <Route element={<PersistLogin />}>
                <Route element={<RequireAuth />}>
                  <Route
                    path="/"
                    element={<Layout children={<Dashboard />} />}
                  />
                  <Route
                    path="/transactions"
                    element={<Layout children={<Remessas />} />}
                  />
                  <Route
                    path="/clients"
                    element={<Layout children={<ClientsTab />} />}
                  />
                  <Route
                    path="/recipient"
                    element={<Layout children={<Beneficiarios />} />}
                  />
                  <Route
                    path="/reports"
                    element={<Layout children={<Reports />} />}
                  />

                  <Route
                    path="/providers"
                    element={<Layout children={<Providers />} />}
                  />
                  <Route
                    path="/settings"
                    element={<Layout children={<SettingsTab />} />}
                  />
                  <Route
                    path="/admin"
                    element={<Layout children={<AdminTab />} />}
                  />
                </Route>
              </Route>
              <Route path="/*" element={<PageNotFound />} />
            </Routes>
          </storeContext.Provider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </LocalizationProvider>
  );
}

export default App;
