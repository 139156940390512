import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Clients from "..";
import Recipients from "../../Recipients";
import Header from "../../../components/Header";
import { useTranslation } from "react-i18next";

export default function ClientsTab() {
  const [value, setValue] = React.useState("1");
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box m="8px">
      <Header
        title={t("clients.header.title")}
        subtitle={t("clients.header.subtitle")}
        color={value === "1" ? "#004549" : "#E35A01"}
      />
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            scrollButtons="auto"
            allowScrollButtonsMobile
            indicatorColor="secondary"
            textColor="inherit"
            onChange={handleChange}
            aria-label="Alterne entre clientes e beneficiários"
          >
            <Tab label={t("clients.tabList.label1")} value="1" />
            <Tab label={t("clients.tabList.label2")} value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">{<Clients />}</TabPanel>
        <TabPanel value="2">{<Recipients />}</TabPanel>
      </TabContext>
    </Box>
  );
}
