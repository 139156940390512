import { useState, useEffect, useDebugValue } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, useMediaQuery, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import axios from "axios";
import LogoutOutlinedIcon from "@mui/icons-material/LoginOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import useLogout from "../../hooks/useLogout";
import Divider from "@mui/material/Divider";
import i18n from "../../utils/i18n";

import usFlag from "../../utils/countryFlags/en-us/us-circle-01-XS.png";
import brFlag from "../../utils/countryFlags/pt-br/br-circle-01-XS.png";
import spFlag from "../../utils/countryFlags/es-sp/es-circle-01-XS.png";
import { useTranslation } from "react-i18next";

const Item = ({ title, to, icon, selected, setSelected, target = "_self" }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.primary[900] }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} target={target} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const [isCollapsed, setIsCollpsed] = useState(false);
  const [cotations, setCotations] = useState({
    USDBRL: { bid: "Carregando", create_date: "Carregando" },
  });
  const [selectedCurrency, setSelectedCurrency] = useState([
    { USDBRL: { bid: "Carregando", create_date: "Carregando" } },
  ]);
  const [iterator, setIterator] = useState("");
  const navigate = useNavigate();
  const logout = useLogout();
  const { t } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const signOut = async () => {
    await logout();
    navigate("/login");
  };
  useEffect(() => {
    if (!isNonMobile) {
      setIsCollpsed(true);
    }
  }, [isNonMobile]);

  const getDolar = async () => {
    try {
      const response = await axios.get(
        "https://economia.awesomeapi.com.br/json/last/USD-BRL,USD-EUR,USD-MXN,USD-DOP"
      );
      setCotations(response.data);
      setSelectedCurrency(response.data.USDBRL);
      setIterator(Object.keys(response.data));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDolar();
  }, []);

  const nextCurrency = () => {
    const keys = Object.keys(cotations);
    const index = keys.indexOf(
      `${selectedCurrency.code}${selectedCurrency.codein}`
    );
    if (index === keys.length - 1) {
      setIterator(keys[0]);
      setSelectedCurrency(cotations[keys[0]]);
    } else {
      setIterator(keys[index + 1]);
      setSelectedCurrency(cotations[keys[index + 1]]);
    }
  };

  const prevCurrency = () => {
    const keys = Object.keys(cotations);
    const index = keys.indexOf(
      `${selectedCurrency.code}${selectedCurrency.codein}`
    );
    if (index === 0) {
      setIterator(keys[keys.length - 1]);
      setSelectedCurrency(cotations[keys[keys.length - 1]]);
    } else {
      setIterator(keys[index - 1]);
      setSelectedCurrency(cotations[keys[index - 1]]);
    }
  };

  return (
    <Box
      position="sticky"
      //height="100vh"
      sx={{
        backgroundColor: `${colors.grey[100]} !important`,
        "& .pro-sidebar-inner": {
          background: `${colors.grey[100]} !important`,
        },
        "& .pro-icon-wrapper": {
          background: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "0 20px 0 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: `${colors.primary[300]} !important`,
        },
        "& .pro-menu-item.active": {
          color: `${colors.primary[500]} !important`,
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <MenuItem
              onClick={() => setIsCollpsed(!isCollapsed)}
              icon={isCollapsed ? <ArrowForwardIosOutlinedIcon /> : undefined}
              style={{ color: colors.primary[500] }}
            />
          </Box>
          {/* USER */}
          {!isCollapsed && (
            <Box>
              <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                <MenuItem
                  onClick={() => setIsCollpsed(!isCollapsed)}
                  icon={<ArrowBackIosOutlinedIcon />}
                  style={{ color: colors.primary[500] }}
                />
              </Box>
              <Box display="flex" justifyContent="left" alignItems="center">
                {theme.palette.mode === "dark" ? (
                  <img
                    src={"../../assets/logo2.png"}
                    height="70px"
                    alt="logo"
                  />
                ) : (
                  <img src={"../../assets/logo.png"} height="70px" alt="logo" />
                )}
              </Box>
            </Box>
          )}

          {/* MENU ITEMS */}
          <Box sx={{ alignContent: "center", justifyContent: "center" }}>
            <Box padding="5px">
              {isCollapsed && (
                <>
                  <Box display="flex" justifyContent="left" alignItems="center">
                    {theme.palette.mode === "dark" ? (
                      <img
                        src={"../../assets/logo_small.png"}
                        height="70px"
                        alt="logo"
                      />
                    ) : (
                      <img
                        src={"../../assets/logo_small.png"}
                        height="70px"
                        alt="logo"
                      />
                    )}
                  </Box>
                  <Box marginTop="5px">
                    <Typography
                      color={colors.primary[900]}
                      variant="h5"
                      fontWeight="400"
                      textAlign="center"
                    >
                      {t("sidebar.dolar")}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    color={colors.primary[500]}
                    backgroundColor={colors.grey[200]}
                    p="5px"
                    borderRadius="4px"
                  >
                    <Box display="flex" justifyContent="space-between">
                      <ArrowBackIosOutlinedIcon onClick={prevCurrency} />
                      <ArrowForwardIosOutlinedIcon onClick={nextCurrency} />
                    </Box>
                    <Typography
                      textAlign="center"
                      variant="h5"
                      fontWeight="600"
                    >{`${selectedCurrency?.codein}`}</Typography>
                    <Typography
                      textAlign="center"
                      variant="h5"
                      fontWeight="600"
                    >{`${parseFloat(selectedCurrency?.bid).toFixed(
                      2
                    )}`}</Typography>
                  </Box>
                  <Typography textAlign="center" color={colors.primary[900]}>
                    {selectedCurrency?.create_date}
                  </Typography>
                </>
              )}
              {!isCollapsed && (
                <>
                  <Box marginTop="5px">
                    <Typography
                      color={colors.primary[900]}
                      variant="h5"
                      fontWeight="400"
                      textAlign="center"
                    >
                      {t("sidebar.dolar")}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    color={colors.primary[500]}
                    backgroundColor={colors.grey[200]}
                    p="5px"
                    borderRadius="4px"
                  >
                    <ArrowBackIosOutlinedIcon onClick={prevCurrency} />
                    <Typography
                      textAlign="center"
                      variant="h5"
                      fontWeight="600"
                    >{`${selectedCurrency?.codein} ${parseFloat(
                      selectedCurrency?.bid
                    ).toFixed(2)}`}</Typography>
                    <ArrowForwardIosOutlinedIcon onClick={nextCurrency} />
                  </Box>
                  <Typography textAlign="center" color={colors.primary[900]}>
                    {selectedCurrency?.create_date}
                  </Typography>
                </>
              )}
            </Box>
            <Divider variant="middle" />
            <Item
              title={isCollapsed ? "" : t("sidebar.dashboard")}
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={t("sidebar.transactions")}
              to="/transactions"
              icon={<CurrencyExchangeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={t("sidebar.clients")}
              to="/clients"
              icon={<PeopleAltOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title={t("sidebar.providers")}
              to="/providers"
              icon={<AccountBalanceOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={t("sidebar.reports")}
              //to="/reports"
              icon={<AssessmentOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={t("sidebar.settings")}
              to="/settings"
              icon={<SettingsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={t("sidebar.onlineSupport")}
              to="https://wa.me/16173475256"
              target="_blank"
              icon={<SupportAgentOutlinedIcon />}
              selected={() => {}}
              setSelected={() => {}}
            />
            <Divider variant="middle" />
            <MenuItem
              style={{ color: colors.primary[900] }}
              onClick={signOut}
              icon={<LogoutOutlinedIcon />}
            >
              <Typography>{t("sidebar.logout")}</Typography>
            </MenuItem>

            <Divider variant="middle" />
          </Box>
        </Menu>
        {!isCollapsed && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              ml: "30%",
              mr: "30%",
            }}
          >
            <Box>
              <img
                src={usFlag}
                style={{ width: "50%", height: "50%", cursor: "pointer" }}
                onClick={() => changeLanguage("en")}
              />
            </Box>
            <Box>
              <img
                src={spFlag}
                style={{ width: "50%", height: "50%", cursor: "pointer" }}
                onClick={() => changeLanguage("es")}
              />
            </Box>
            <Box>
              <img
                src={brFlag}
                style={{ width: "50%", height: "50%", cursor: "pointer" }}
                onClick={() => changeLanguage("pt")}
              />
            </Box>
          </Box>
        )}
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
